import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/index.scss'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import useSiteMetadata from './SiteMetadata'

const TemplateWrapper = ({ children, data }) => {
  const [bgImg, setBgImage] = useState(null) //data && data.backgroundImages ? data.backgroundImages.sort(() => .5 - Math.random())[0] : ''
  const { title, description } = useSiteMetadata()
  const [loaded, setLoaded] = useState(false)
  const mainRef = useRef(null)
  const executeScroll = () => mainRef.current.scrollIntoView()
  useEffect(() => {
    if (!loaded) {
      setBgImage(data && data.backgroundImages ? data.backgroundImages.sort(() => .5 - Math.random())[0] : '')
      setLoaded(true)
    }
  }, [loaded])

  return (
    <div className={loaded ? 'cLayout loaded' : 'cLayout'} style={bgImg ? {backgroundImage: `url('${bgImg.image.childImageSharp.fluid.src}')`} : null}>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
        <link rel="apple-touch-icon" sizes="57x57" href="/img/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/img/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/img/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/img/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/img/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/img/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/img/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/img/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/img/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192"  href="/img/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/img/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/img/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/img/favicon-16x16.png" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.png?v=2`}
        />
      </Helmet>
      <Navbar executeScroll={executeScroll} />
      <div ref={mainRef}>{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
