import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from "gatsby"

const MyFooter = ({ data }) => {
  const { footer } = data.markdownRemark.frontmatter
  const telephoneNumber = footer.telephone ? footer.telephone.split('-').join('').trim() : ''
  return (
    <footer className="cFooter">
      <div className="cFooter-inner inner">
        <div className="cFooter-content">
          <div className="cFooter-contact">
            <address className="cFooter-detail">
              {footer.address && footer.address.link && footer.address.lineOne &&
                <a href={footer.address.link} className="cFooter-link">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-arrow" className="svg-inline--fa fa-location-arrow fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M444.52 3.52L28.74 195.42c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 51.17 70.36 67.17 92.75 19.19l191.9-415.78c15.99-38.39-25.59-79.97-63.97-63.97z"/></svg>
                  {footer.address && footer.address.lineOne}<br/>{footer.address && footer.address.lineTwo}
                </a>
              }
            </address>
              {footer.email &&
              <div className="cFooter-detail">
                <a href={`mailto:${footer.email}`} className="cFooter-link">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" className="svg-inline--fa fa-envelope fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"/></svg>
                  {footer.email}
                </a>
              </div>
              }
              {footer.telephone &&
                <div className="cFooter-detail">
                  <a href={`tel:+${telephoneNumber}`} className="cFooter-link">
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone" className="svg-inline--fa fa-phone fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"/></svg>
                    {footer.telephone}
                  </a>
                </div>
              }
          </div>
        </div>
        <p className="cFooter-text">{footer.copyrightText}</p>
      </div>
    </footer>
  )
}

export default function Footer(props) {
  return (
    <StaticQuery
      query={graphql`
        query Footer {
          markdownRemark(frontmatter: {type: {eq: "global_config"}})  {
            frontmatter {
              footer {
                address {
                  lineOne
                  lineTwo
                  link
                }
                email
                telephone
                copyrightText
              }
            }
          }
        }
      `}
      render={data => <MyFooter data={data} {...props} />}
    />
  )
}

MyFooter.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        footer: PropTypes.shape({
          address: PropTypes.shape({
            lineOne: PropTypes.string,
            lineTwo: PropTypes.string,
            link: PropTypes.string
          }),
          email: PropTypes.string,
          telephone: PropTypes.string,
          copyrightText: PropTypes.string
        })
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
